import { AxiosResponse } from "axios";
import { isNumber } from "is-number-ts";
import moment from "moment";
import toast from "react-hot-toast";
import { environments } from "../environments";
import {
  AnyData,
  CamposDeValorUnico,
  Foto360,
  GenericObject,
  IData,
  IFormularios,
  ILoginResponse,
  IRelease,
  ImportacoesTiff,
  PermissoesProjetos,
  ProjetosCamadasFormulariosCampo,
  Tabelas,
} from "../interfaces";
import { apiCadastro } from "../repositories/api.cadastro";
import { apiGateway } from "../repositories/api.gateway";
import { apiPlataforma } from "../repositories/api.plataforma";
import pj from "./../../package.json";
import { getMunicipio, verifyIfInscricaoOrNumeroCadastroAlreadyExistsOnLocalDatabase } from "./data";

/**
 * Realiza uma tentativa de login na plataforma com o email e a senha fornecidos.
 *
 * @async
 * @param {string} email - O endereço de email do usuário.
 * @param {string} senha - A senha do usuário.
 * @returns {Promise<ILoginResponse | undefined>} Uma promessa que será resolvida com um objeto de resposta de login se o login for bem-sucedido, ou undefined se o login falhar.
 *
 * @example
 * const email = "user@example.com";
 * const senha = "senha123";
 *
 * try {
 *   const resultadoLogin = await login(email, senha);
 *   if (resultadoLogin) {
 *     console.log("Login bem-sucedido. Token de acesso:", resultadoLogin.token);
 *   } else {
 *     console.warn("Login falhou. Verifique suas credenciais.");
 *   }
 * } catch (error) {
 *   console.error("Erro ao tentar fazer login:", error);
 * }
 */

export async function login(email: string, senha: string): Promise<ILoginResponse | undefined> {
  try {
    const login = await apiPlataforma.post("/login", {
      email: email,
      senha: senha,
    });

    localStorage.setItem("email", email);

    return login.data;
  } catch (error) {
    console.error("Não foi possível realizar o login", error);
  }
}

/**
 * Obtém as configurações do tenant da plataforma.
 *
 * @async
 * @returns {Promise<{ [key: string]: any } | undefined>} Uma promessa que será resolvida com um objeto contendo as configurações do tenant se a operação for bem-sucedida, ou undefined se falhar.
 *
 * @example
 * try {
 *   const configTenant = await getTenantConfig();
 *   if (configTenant) {
 *     console.log("Configurações do tenant obtidas com sucesso:", configTenant);
 *   } else {
 *     console.warn("Não foi possível obter as configurações do tenant.");
 *   }
 * } catch (error) {
 *   console.error("Erro ao obter as configurações do tenant:", error);
 * }
 */

export async function getTenantConfig(): Promise<{ [key: string]: any } | undefined> {
  try {
    const response = await apiPlataforma.get("/tenants/config");
    let tenantConfig = JSON.parse(response.data.config);
    
    if (('configCaracterizacao' in tenantConfig)== false) { 
      tenantConfig.configCaracterizacao = {
        desabilitaLote: false,
        desabilitaImobiliario: false,
        desabilitaPessoa: false,
        desabilitaEdificacao: false,
        desabilitaVisita: false,
        desabilitaControle: false
      };
    }

    return tenantConfig;
  } catch (error) {
    console.error("Não foi possível obter as configurações do tenant, verifique a estrutura do JSON", error);
    return undefined;
  }
}


/**
 * Obtém os dados de um recurso com base em seu ID.
 *
 * @async
 * @param {number} id - O ID do recurso a ser obtido.
 * @returns {Promise<IData | undefined>} Uma promessa que será resolvida com os dados do recurso se a operação for bem-sucedida, ou undefined se falhar.
 *
 * @example
 * const resourceId = 123;
 * try {
 *   const resourceData = await getData(resourceId);
 *   if (resourceData) {
 *     console.log("Dados do recurso obtidos com sucesso:", resourceData);
 *   } else {
 *     console.warn("Não foi possível obter os dados do recurso.");
 *   }
 * } catch (error) {
 *   console.error("Erro ao obter os dados do recurso:", error);
 * }
 */

export async function getData(id: number): Promise<IData | undefined> {
  try {
    const request = await apiCadastro.get(`/caracterizacao/${getMunicipio()}/get-data/${id}`);

    return request.data;
  } catch (error) {
    console.error("Não foi possível obter os dados do lote", error);
  }
}

/**
 * Obtém a estrutura de dados de uma tabela específica da API REST.
 *
 * @param {string} table - O nome da tabela para a qual se deseja obter a estrutura de dados.
 * @returns {Promise<IData | undefined>} - Uma Promise que resolve para a estrutura de dados da tabela ou undefined se houver um erro.
 * @throws {Error} - Lança um erro se não for possível obter os dados da tabela.
 */

export async function getDataStructureByTable(table: string): Promise<IData | undefined> {
  try {
    const request = await apiGateway.get(`/rest/${table}?select=*&limit=1`);

    if (!request.data[0] || request.data.length === 0)
      console.warn(`Não há dados na tabela ${table}, não foi possível obter a estrutura de dados.`);

    return request.data[0];
  } catch (error) {
    console.error(`Não foi possível obter os dados da tabela ${table}`, error);
  }
}

/**
 * Obtém os formulários associados a tabelas de um projeto no contexto da plataforma.
 *
 * @async
 * @returns {Promise<IFormularios | undefined>} Uma promessa que será resolvida com os formulários se a operação for bem-sucedida, ou undefined se falhar.
 *
 * @example
 * try {
 *   const forms = await getForms();
 *   if (forms) {
 *     console.log("Formulários obtidos com sucesso:", forms);
 *   } else {
 *     console.warn("Não foi possível obter os formulários.");
 *   }
 * } catch (error) {
 *   console.error("Erro ao obter os formulários:", error);
 * }
 */

export async function getForms(): Promise<IFormularios | undefined> {
  try {
    const projetos = (await apiPlataforma.get("/projetos/listar?ativo=TODOS")).data;

    const projetoIcad = projetos.filter((projeto: GenericObject) => projeto.tipoProjeto === "ICADPADRAO")[0];

    const projetoCamadas = (await apiPlataforma.get("/projetos/" + projetoIcad.id)).data.projetosCamadas;

    const Tabelas = [
      "lote",
      "imobiliario",
      "imobiliario_edificacao",
      "edificacao",
      "pessoa",
      "imobiliario_pessoa",
      "visitas",
      "mensagens",
      "midia",
    ];

    const camadasUtilizadas: { [x: string]: any } = {};
    Tabelas.map((table: string) => {
      camadasUtilizadas[table] = projetoCamadas.filter((camada: any) => camada.representacao === table)[0];

      if (!camadasUtilizadas[table]) {
        throw new Error("Não existe nenhuma camada no projeto Cadastro Imobiliário com a representação " + table);
      }
    });

    const formularios: { [x: string]: ProjetosCamadasFormulariosCampo[] } = {};

    await Promise.all(
      Tabelas.map(async (table: string) => {
        formularios[table] = (
          await apiPlataforma.get("/camadas/" + camadasUtilizadas[table].id)
        ).data.projetosCamadasFormularios.filter((e) => e.ativo);
      }),
    );

    return formularios as unknown as IFormularios;
  } catch (error) {
    console.error("Não foi possível obter os formulários", error);
  }
}

/**
 * Obtém o ID do primeiro lote disponível na fonte de dados.
 *
 * @async
 * @returns {Promise<string | undefined>} Uma promessa que será resolvida com o ID do primeiro lote se a operação for bem-sucedida, ou undefined se falhar.
 *
 * @example
 * try {
 *   const firstLoteId = await getFirstIdLote();
 *   if (firstLoteId) {
 *     console.log("ID do primeiro lote:", firstLoteId);
 *   } else {
 *     console.warn("Não foi possível obter o ID do primeiro lote.");
 *   }
 * } catch (error) {
 *   console.error("Erro ao obter o ID do primeiro lote:", error);
 * }
 */

export async function getFirstIdLote(): Promise<any> {
  try {
    const firstLote = await apiGateway.get("/rest/lote?limit=1");

    return firstLote.data[0].id;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Obtém uma foto em 360 graus (Foto360) com base em coordenadas geográficas.
 *
 * @async
 * @param {number[]} coords - As coordenadas geográficas (latitude e longitude) para a busca da foto em 360 graus.
 * @returns {Promise<Foto360 | undefined>} Uma promessa que será resolvida com a Foto360 se a operação for bem-sucedida, ou undefined se falhar.
 *
 * @example
 * const latitude = 123.456;
 * const longitude = 789.012;
 * const coordinates = [latitude, longitude];
 *
 * try {
 *   const photoSphere = await getPhotoSphere(coordinates);
 *   if (photoSphere) {
 *     console.log("Foto em 360 graus obtida com sucesso:", photoSphere);
 *   } else {
 *     console.warn("Não foi possível obter a foto em 360 graus para as coordenadas fornecidas.");
 *   }
 * } catch (error) {
 *   console.error("Erro ao obter a foto em 360 graus:", error);
 * }
 */

export async function getPhotoSphere(coords: number[]): Promise<Foto360 | undefined> {
  try {
    const response: { [key: string]: any } = await apiCadastro.get(
      `/caracterizacao/${localStorage.municipio}/foto_360/coords/${coords[0]}/${coords[1]}/`,
    );

    return response.data[0];
  } catch (error) {
    console.error(error);
  }
}

/**
 * Envia logs para uma fonte de dados específica.
 *
 * @async
 * @returns {Promise<any>} Uma promessa que será resolvida com a resposta da solicitação de envio de logs, ou rejeitada com o erro, se a operação for bem-sucedida ou falhar.
 *
 * @example
 * try {
 *   const response = await sendLogs();
 *   console.log("Logs enviados com sucesso:", response);
 * } catch (error) {
 *   console.error("Erro ao enviar logs:", error);
 * }
 */

export async function sendLogs(): Promise<any> {

  const logFromStorage = localStorage.getItem("log")
  if (!logFromStorage) {
    toast("Nâo há dados para salvar");
    return;
  }
  const logs = JSON.parse(logFromStorage);
  logs.application = `Aplicação de Caracterização - v${pj.version} - ${environments.ambiente}`;

  try {
    return apiCadastro.post(`/caracterizacao/${localStorage.municipio}/processar-logs`, logs).then((response) => {
      return response.data;
    });
  } catch (error) {
    return error;
  } finally {
    toast.success("Dados foram salvos com sucesso");
  }
}

/**
 * Envia um arquivo para armazenamento em uma fonte de dados específica.
 *
 * @async
 * @param {string} nome - O nome do arquivo.
 * @param {string} arquivo - O conteúdo do arquivo em formato base64.
 * @param {string} extensao - A extensão do arquivo.
 * @returns {Promise<any>} Uma promessa que será resolvida com a resposta da solicitação de envio de arquivo, ou rejeitada com o erro, se a operação for bem-sucedida ou falhar.
 *
 * @example
 * const fileName = "example.jpg";
 * const fileContent = "base64-encoded-content";
 * const fileExtension = "jpg";
 *
 * try {
 *   const response = await sendFile(fileName, fileContent, fileExtension);
 *   console.log("Arquivo enviado com sucesso:", response);
 * } catch (error) {
 *   console.error("Erro ao enviar arquivo:", error);
 * }
 */

export async function sendFile(nome: string, arquivo: string, extensao: string): Promise<AxiosResponse<any, any>> {
  try {
    return await apiCadastro.post(`/midia/${getMunicipio()}/storage`, {
      nome: nome,
      arquivo: arquivo,
      extensao: extensao,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Obtém informações sobre as importações de arquivos TIFF com base em filtros específicos.
 *
 * @async
 * @returns {Promise<ImportacoesTiff[]>} Uma promessa que será resolvida com um array de objetos ImportacoesTiff se a operação for bem-sucedida.
 *
 * @example
 * try {
 *   const importacoes = await getImportacoesTiff();
 *   console.log("Importações de arquivos TIFF obtidas com sucesso:", importacoes);
 * } catch (error) {
 *   console.error("Erro ao obter informações sobre as importações de arquivos TIFF:", error);
 * }
 */

export async function getImportacoesTiff(): Promise<ImportacoesTiff[]> {
  try {
    const request = await apiPlataforma.get("/importacoes-tiff?filtros=representacao,eq,BaseMapLocal");

    return request.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Envia feedback de sincronização para o sistema de rastreamento de problemas (Easy Redmine).
 * @param {IObjectError[]} errosLog - Os erros de log.
 * @returns {Promise<IIssueOnEasyRedmine>} Uma Promise que resolve quando o feedback é enviado com sucesso.
 * @throws {Error} Lança um erro se houver um problema ao enviar o feedback.
 */

export async function sendFeedbackSincronizacao(errosLog: any): Promise<any> {
  const ambiente = environments.ambiente;
  const urlApiEspecifica = environments.urlApiCadastro;
  const urlApiGateway = environments.urlApiGateway;
  const urlApiGenerica = environments.urlApiPlataforma;
  const versao = pj.version;
  const userAuthToken = localStorage.xAuthToken;
  const tenantToken = localStorage.xTnToken;
  const nomeMunicipio = getMunicipio();
  const logData = localStorage.getItem("log") || "{}";
  const email = localStorage.email;

  const body = {
    issue: {
      subject: `Relatório de Erros - Caracterização - ${nomeMunicipio}- ${versao}`,
      description: `Detalhes:\n
                    Versão da aplicação: ${versao} - ${ambiente}\n
                    URL da API Específica: ${urlApiEspecifica}\n
                    URL da API Gateway: ${urlApiGateway}\n
                    URL da API Genérica: ${urlApiGenerica}\n
                    Municipio: ${nomeMunicipio}\n
                    Token do Usuário: ${userAuthToken}\n
                    Token do Tenant: ${tenantToken}\n
                    Data e hora do erro: ${moment().format("DD MMMM YYYY, h:mm:ss a")}`,
      easy_email_to: email,
      easy_email_cc: email,
      project_id: 185,
      tracker_id: 12,
      status_id: 2,
      author_id: 4,
      priority_id: 12,
      custom_fields: [
        {
          id: 121,
          value: logData,
        },
        {
          id: 122,
          value: JSON.stringify(errosLog),
        },
        {
          id: 123,
          value: `Bearer ${userAuthToken}`,
        },
      ],
    },
  };
  try {
    return await apiCadastro.post("/caracterizacao/criar-issue-easyredmine", body);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Obtém os logradouros mais próximos a um lote com base em um ID de lote específico.
 *
 * @async
 * @param {string} id_lote - O ID do lote para o qual você deseja encontrar logradouros próximos.
 * @returns {Promise<AnyData[]>} Uma promessa que será resolvida com um array de objetos AnyData representando os logradouros mais próximos se a operação for bem-sucedida.
 *
 * @example
 * const loteId = "12345"; // Substitua com o ID do lote desejado
 *
 * try {
 *   const logradourosProximos = await getLogradouroMaisProximo(loteId);
 *   console.log("Logradouros mais próximos obtidos com sucesso:", logradourosProximos);
 * } catch (error) {
 *   console.error("Erro ao obter os logradouros mais próximos:", error);
 * }
 */

export async function getLogradouroMaisProximo(id_lote: string): Promise<AnyData> {
  try {
    const request = await apiCadastro.get(
      `/caracterizacao/${getMunicipio()}/nearest-features/lote/${id_lote}/logradouro/10`,
    );

    return request.data[0];
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Busca opções de dados da API com base no tipo e nome fornecidos.
 *
 * @param {string} type - O tipo de recurso a ser consultado na API.
 * @param {string} name - O nome do campo a ser selecionado na resposta da API.
 * @returns {Promise<AnyData[]>} - Uma promessa que resolve para um array de dados de opções.
 * @throws {Error} - Lança um erro se a requisição à API falhar.
 */
export async function getDescriptions(type: string, name: string): Promise<AnyData[]> {
  try {
    const { data } = await apiGateway.get(`/rest/${type}?select=id,${name}&limit=50`);
    return data;
  } catch (error) {
    throw error;
  }
}

/**
 * Busca opções de dados da API com base no tipo e valor do ID fornecidos.
 *
 * @param {string} type - O tipo de recurso a ser consultado na API.
 * @param {string} value - O valor do ID a ser filtrado na consulta.
 * @returns {Promise<AnyData[]>} - Uma promessa que resolve para um array de dados de opções.
 * @throws {Error} - Lança um erro se a requisição à API falhar.
 */
export async function getDescriptionsById(type: string, value: string): Promise<AnyData[]> {
  try {
    const { data } = await apiGateway.get(`rest/${type}?id=eq.${value}`);
    return data;
  } catch (error) {
    throw error;
  }
}

/**
 * Busca opções de dados da API com base no tipo, valor do nome e nome do campo fornecidos.
 *
 * @param {string} type - O tipo de recurso a ser consultado na API.
 * @param {string} value - O valor do nome a ser filtrado na consulta.
 * @param {string} name - O nome do campo a ser selecionado na resposta da API.
 * @returns {Promise<AnyData[]>} - Uma promessa que resolve para um array de dados de opções.
 * @throws {Error} - Lança um erro se a requisição à API falhar.
 */
export async function getDescriptionsByName(type: string, value: string, name: string): Promise<AnyData[]> {
  try {
    const { data } = await apiGateway.get(`/rest/${type}?select=id,${name}&limit=50&${name}=ilike.*${value}*`);
    return data;
  } catch (error) {
    throw error;
  }
}

/**
 * Verifica se uma inscrição cartográfica ou um número cadastro específicos já existem na tabela especificada.
 *
 * @param {string} table - A tabela onde o campo será procurado.
 * @param {string} id - O valor a ser comparado na busca da inscrição cartográfica.
 * @param {CamposDeValorUnico} campo - O nome do campo pelo qual a busca será realizada.
 * @param {string} currentId - O ID atual da feição para ser excluído da busca.
 * @returns {Promise<boolean>} - Uma Promise que resolve para true se a inscrição cartográfica existir, ou false se não existir ou houver um erro.
 * @throws {Error} - Lança um erro se ocorrer um problema ao realizar a busca.
 */
export async function verifyIfInscricaoOrNumeroCadastroAlreadyExists(
  table: Tabelas,
  value: string,
  campo: CamposDeValorUnico,
  currentId: string,
): Promise<boolean> {
  try {
    let existsOnRemote: boolean;
    if (isNumber(currentId)) {
      const { data } = await apiGateway.get(`/rest/${table}?${campo}=eq.${value}&id=neq.${currentId}`);
      existsOnRemote = data.length > 0;
    } else {
      const { data } = await apiGateway.get(`/rest/${table}?${campo}=eq.${value}`);
      existsOnRemote = data.length > 0;
    }

    const existsOnLocalDatabase = verifyIfInscricaoOrNumeroCadastroAlreadyExistsOnLocalDatabase(
      table,
      value,
      campo,
      currentId,
    );

    return existsOnRemote || existsOnLocalDatabase;
  } catch (error) {
    console.warn(error);
    return false;
  }
}

/**
 * Obtém as releases do GitHub da aplicação.
 *
 * @throws {Error} Lança um erro se a requisição falhar.
 * @returns {Promise<IRelease[]>} Uma Promise que resolve para um array de objetos IRelease.
 */
export async function getGithubReleases(): Promise<IRelease[]> {
  try {
    const response = await apiCadastro.get(`/caracterizacao/github-releases`);
    return response.data;
  } catch (error) {
    console.error("Não foi possível obter as releases", error);
    throw error;
  }
}

/**
 * Busca as permissões do projeto na API Plataforma para o usuario autenticado.
 * @async
 * @function getPermissoesProjetos
 * @returns {Promise<PermissoesProjetos | undefined>} Uma promessa que resolve para as permissões do projeto ou indefinido se ocorrer um erro.
 * @throws Lançará um erro se a solicitação da API falhar.
 */
export async function getPermissoesProjetos(): Promise<PermissoesProjetos | undefined> {
  try {
    const projetos = (await apiPlataforma.get("/projetos/listar?ativo=TODOS")).data;

    const projetoIcad = projetos.filter((projeto: GenericObject) => projeto.tipoProjeto === "ICADPADRAO")[0];

    const permissoes: PermissoesProjetos[] = (await apiPlataforma.get("/permissoes/projetos")).data;

    permissoes.find((e) => e.projetoId === projetoIcad.id);

    if (permissoes.length === 0) {
      throw new Error("Não foi possível obter as permissões dos projetos");
    }

    return permissoes[0];
  } catch (error) {
    console.error("Não foi possível obter as permissões dos projetos", error);
  }
}
