import { v4 as uuid } from "uuid";
import {
  AnyData,
  CamposDeValorUnico,
  GenericObject,
  IToken,
  ImobiliarioPessoa,
  Midia,
  Pessoa,
  Tabelas,
} from "../interfaces";
import { getLogradouroMaisProximo, sendFile } from "./api";
import { del, getAll, getStructure, patch, put } from "./db";
import { logDelete, logInsert, logUpdate } from "./log";
import { getPreenchimentoParaNovoImobiliario, novaInscricaoCartografica, novoNumeroCadastro } from "./utils";

/**
 * Armazena os tokens de autenticação no armazenamento local (localStorage).
 *
 * @async
 * @param {string} xTnToken - O token xTnToken a ser armazenado.
 * @param {string} xAuthToken - O token xAuthToken a ser armazenado.
 * @returns {Promise<void>} Uma promessa que será resolvida após os tokens serem armazenados com sucesso.
 * @throws {Error} Lança um erro se ocorrer um problema ao armazenar os tokens.
 *
 * @example
 * const xTnToken = "meu_xTnToken";
 * const xAuthToken = "meu_xAuthToken";
 * try {
 *   await setTokens(xTnToken, xAuthToken);
 *   console.log("Tokens armazenados com sucesso!");
 * } catch (error) {
 *   console.error("Ocorreu um erro ao armazenar os tokens:", error);
 * }
 */
export async function setTokens(xTnToken: string, xAuthToken: string): Promise<void> {
  localStorage.setItem("xTnToken", xTnToken);
  localStorage.setItem("xAuthToken", xAuthToken);
  localStorage.setItem("errors", "{}");
}

/**
 * Define uma string vazia "{}" como valor para a chave "errors" no localStorage.
 *
 * @returns {void} - A função não retorna um valor específico.
 */
export function setErrors(): void {
  localStorage.setItem("errors", "{}");
}

/**
 * Obtém os tokens de autenticação armazenados no armazenamento local (localStorage).
 *
 * @returns {IToken} Um objeto contendo os tokens de autenticação.
 *
 * @typedef {Object} IToken
 * @property {string} x-tn-token - O valor do token x-tn-token.
 * @property {string} x-auth-token - O valor do token x-auth-token.
 *
 * @example
 * const tokens = getTokens();
 * console.log("Tokens de autenticação obtidos:", tokens);
 */

export function getTokens(): IToken {
  return {
    "x-tn-token": localStorage.getItem("xTnToken" || undefined)!,
    "x-auth-token": localStorage.getItem("xAuthToken" || undefined)!,
  };
}

/**
 * Armazena o nome do município no armazenamento local (localStorage).
 *
 * @param {string} municipio - O nome do município a ser armazenado.
 * @throws {Error} Lança um erro se ocorrer um problema ao armazenar o município.
 *
 * @example
 * const nomeMunicipio = "Minha Cidade";
 * try{
 *   setMunicipio(nomeMunicipio);
 *   console.log("Município armazenado com sucesso:", nomeMunicipio);
 * } catch (error) {
 *   console.error("Ocorreu um erro ao armazenar o município:", error);
 * }
 */
export function setMunicipio(municipio: string) {
  localStorage.setItem("municipio", municipio);
}

/**
 * Obtém o nome do município armazenado no armazenamento local (localStorage).
 *
 * @returns {string} O nome do município, ou a string "municipio" se não estiver armazenado.
 *
 * @example
 * const nomeMunicipio = getMunicipio();
 * console.log("Nome do município obtido:", nomeMunicipio);
 */

export function getMunicipio(): string {
  return localStorage.getItem("municipio") || "municipio";
}

/**
 * Salva dados em uma tabela específica, atualizando ou adicionando conforme necessário.
 *
 * @param {AnyData} data - Os dados a serem salvos na tabela.
 * @param {Tabelas} tb - A tabela na qual os dados serão salvos.
 * @throws {Error} Lança um erro se ocorrer um problema ao salvar os dados.
 *
 * @example
 * const dataToSave = {
 *   id: 123,
 *   name: "Exemplo",
 *   geom: { type: "Point", coordinates: [45.0, 60.0] }
 * };
 * const tabela = Tabelas.EXEMPLO;
 * try {
 *   saveData(dataToSave, tabela);
 *   console.log("Dados salvos com sucesso!");
 * } catch (error) {
 *   console.error(`Ocorreu um erro ao salvar os dados na tabela ${tabela}:`, error);
 * }
 */

export function saveData(data: AnyData, tb: Tabelas) {
  try {
    if (!data.id) {
      return;
    }

    const id = data.id.toString();

    Object.keys(data).forEach((key) => {
      let value = data[key];

      if (key === "geom") {
        value = JSON.stringify(value);
      }
    });

    put(id, tb, data);
  } catch (error) {
    console.error(`Erro ao salvar ${tb}`, error, data);
  }
}

/**
 * Armazena um valor no armazenamento local como uma string JSON.
 *
 * @param {string} value - O valor a ser armazenado.
 * @returns {void}
 *
 * @example
 * const valor = "123";
 * setLoadedId(valor);
 */

export function setLoadedId(value: string): void {
  localStorage.setItem("loaded", JSON.stringify(value));
}

/**
 * Verifica se um valor específico está armazenado no armazenamento local.
 *
 * @param {string} value - O valor a ser verificado.
 * @returns {boolean} Retorna true se o valor estiver armazenado, caso contrário, retorna false.
 *
 * @example
 * const valor = "123";
 * const carregado = isIdLoaded(valor);
 * console.log(`O valor ${valor} está carregado? ${carregado}`);
 */

export function isIdLoaded(value: string): boolean {
  return JSON.parse(localStorage.getItem("loaded")!) === value;
}

/**
 * Cria um registro de imóvel e realiza várias operações relacionadas, como a geração de IDs e log de atualizações.
 *
 * @async
 * @param {string} id_lote - O ID do lote associado ao imóvel.
 * @returns {Promise<object>} Um objeto representando o registro de imóvel criado.
 * @throws {Error} Lança um erro se ocorrer um problema durante o processo de criação.
 *
 * @example
 * const idLote = "123";
 * try {
 *   const imovel = await createImobiliario(idLote);
 *   console.log("Registro de imóvel criado com sucesso:", imovel);
 * } catch (error) {
 *   console.error("Erro ao criar registro de imóvel:", error);
 * }
 */

export async function createImobiliario(id_lote: string): Promise<GenericObject> {
  let imobiliarioStructure = getStructure("imobiliario");

  imobiliarioStructure.id = uuid();
  imobiliarioStructure.id_lote = Number(id_lote);

  try {
    const inscricao_cartografica = await novaInscricaoCartografica(id_lote);
    if (inscricao_cartografica && imobiliarioStructure.hasOwnProperty("inscricao_cartografica")) {
      imobiliarioStructure.inscricao_cartografica = inscricao_cartografica;

      logUpdate({
        id_origem: imobiliarioStructure.id,
        nome_tabela: "imobiliario",
        nome_campo: "inscricao_cartografica",
        tipo_campo: "text",
        valor_campo: imobiliarioStructure.inscricao_cartografica,
      });
    }
  } catch (error) {
    console.warn("Não foi possivel obter a inscrição cartográfica do novo imobiliário:", error);
  }

  try {
    const numero_cadastro = await novoNumeroCadastro();
    if (numero_cadastro && imobiliarioStructure.hasOwnProperty("numero_cadastro")) {
      imobiliarioStructure.numero_cadastro = numero_cadastro;

      logUpdate({
        id_origem: imobiliarioStructure.id,
        nome_tabela: "imobiliario",
        nome_campo: "numero_cadastro",
        tipo_campo: "text",
        valor_campo: imobiliarioStructure.numero_cadastro,
      });
    }
  } catch (error) {
    console.warn("Não foi possivel obter o numero_cadastro novo imobiliário:", error);
  }

  try {
    if (imobiliarioStructure.hasOwnProperty("id_logradouro")) {
      const nearestLogradouro = await getLogradouroMaisProximo(id_lote);
      imobiliarioStructure.id_logradouro = nearestLogradouro.id;

      logUpdate({
        id_origem: imobiliarioStructure.id,
        nome_tabela: "imobiliario",
        nome_campo: "id_logradouro",
        tipo_campo: "integer",
        valor_campo: imobiliarioStructure.id_logradouro,
      });
    }
  } catch (error) {
    console.warn("Não foi possivel obter o logradouro mais próximo do novo imobiliário:", error);
  }

  try {
    const preenchimentosParaNovoImobiliario = await getPreenchimentoParaNovoImobiliario(id_lote);

    Object.keys(preenchimentosParaNovoImobiliario).forEach((key) => {
      if (key in imobiliarioStructure) imobiliarioStructure[key] = preenchimentosParaNovoImobiliario[key];

      logUpdate({
        id_origem: imobiliarioStructure.id,
        nome_tabela: "imobiliario",
        nome_campo: key,
        tipo_campo: "text",
        valor_campo: preenchimentosParaNovoImobiliario[key],
      });
    });
  } catch (error) {
    console.warn("Não foi possivel obter os valores a serem preenchidos para o novo imobiliário:", error);
  }

  put(imobiliarioStructure.id, "imobiliario", imobiliarioStructure);

  logInsert({
    fid: imobiliarioStructure.id,
    nome_tabela: "imobiliario",
  });

  logUpdate({
    id_origem: imobiliarioStructure.id,
    nome_tabela: "imobiliario",
    nome_campo: "id_lote",
    tipo_campo: "integer",
    valor_campo: Number(id_lote),
  });

  return imobiliarioStructure;
}

/**
 * Marca um imóvel (imobiliário) como excluído, atualizando o status no armazenamento local e no registro de log.
 * @param {string} id_imobiliario - O ID do imóvel a ser marcado como excluído.
 * @returns {boolean} - Retorna verdadeiro se a exclusão for bem-sucedida, caso contrário, lança uma exceção.
 * @throws {Error} - Lança um erro se o imóvel não possui o atributo "status".
 */

export function deleteImobiliario(id_imobiliario: string): boolean {
  const structureImobiliario = getStructure("imobiliario");
  if (structureImobiliario.hasOwnProperty("status")) {
    patch(id_imobiliario, "imobiliario", "status", 2);

    logUpdate({
      id_origem: id_imobiliario,
      nome_tabela: "imobiliario",
      nome_campo: "status",
      tipo_campo: "integer",
      valor_campo: 2,
    });
    return true;
  } else {
    console.warn("Imobiliário não possui atributo status");
    return false;
  }
}

/**
 * Atualiza o tipo de imposto de um lote no armazenamento local e no registro de log para valor correspondente ao territorial.
 * @param {string} id_lote - O ID do lote para o qual o tipo de imposto será atualizado.
 * @returns {boolean} - Retorna verdadeiro se a atualização for bem-sucedida, caso contrário, lança uma exceção.
 * @throws {Error} - Lança um erro se o lote não possui o atributo "tipo_imposto".
 */

export function patchTipoImpostoTo2(id_lote: string): boolean {
  const structureImobiliario = getStructure("lote");
  if (structureImobiliario.hasOwnProperty("tipo_imposto")) {
    patch(id_lote, "lote", "tipo_imposto", "2");

    logUpdate({
      id_origem: id_lote,
      nome_tabela: "lote",
      nome_campo: "tipo_imposto",
      tipo_campo: "integer",
      valor_campo: "2",
    });

    return true;
  } else {
    console.warn("Lote não possui atributo tipo_imposto");
    return false;
  }
}

/**
 * Adiciona uma pessoa a um registro de imóvel e executa operações relacionadas, como a criação de registros e logs.
 *
 * @param {Pessoa} pessoa - A pessoa a ser adicionada.
 * @param {string} id_imobiliario - O ID do registro de imóvel ao qual a pessoa será associada.
 * @returns {ImobiliarioPessoa} Um objeto representando a associação entre a pessoa e o imóvel.
 *
 * @example
 * const pessoaParaAdicionar = { id: "123", nome: "John Doe" };
 * const idImobiliario = "456";
 * const associacao = addPessoaToImobiliario(pessoaParaAdicionar, idImobiliario);
 * console.log("Pessoa associada ao imóvel:", associacao);
 */
export function addPessoaToImobiliario(pessoa: Pessoa, id_imobiliario: string): ImobiliarioPessoa {
  put(String(pessoa.id), "pessoa", pessoa);

  let pessoaImobiliarioStructure = getStructure("imobiliario_pessoa");

  pessoaImobiliarioStructure.id = uuid();
  pessoaImobiliarioStructure.id_imobiliario = Number(id_imobiliario);
  pessoaImobiliarioStructure.id_pessoa = Number(pessoa.id);

  put(pessoaImobiliarioStructure.id, "imobiliario_pessoa", pessoaImobiliarioStructure);

  logInsert({
    fid: pessoaImobiliarioStructure.id,
    nome_tabela: "imobiliario_pessoa"
  });

  logUpdate({
    id_origem: pessoaImobiliarioStructure.id,
    nome_tabela: "imobiliario_pessoa",
    nome_campo: "id_pessoa",
    tipo_campo: "integer",
    valor_campo: pessoa.id
  });

  logUpdate({
    id_origem: pessoaImobiliarioStructure.id,
    nome_tabela: "imobiliario_pessoa",
    nome_campo: "id_imobiliario",
    tipo_campo: "integer",
    valor_campo: id_imobiliario
  });

  return pessoaImobiliarioStructure;
}

/**
 * Cria um novo registro de pessoa e executa operações relacionadas, como a geração de IDs e logs.
 *
 * @returns {Pessoa} Um objeto representando a nova pessoa criada.
 *
 * @example
 * const novaPessoa = createPessoa();
 * console.log("Nova pessoa criada:", novaPessoa);
 */

export function createPessoa(): Pessoa {
  let pessoaStructure = getStructure("pessoa");

  pessoaStructure.id = uuid();

  put(pessoaStructure.id, "pessoa", pessoaStructure);

  logInsert({
    fid: pessoaStructure.id,
    nome_tabela: "pessoa"
  });

  return pessoaStructure;
}

/**
 * Remove uma associação entre uma pessoa e um registro de imóvel e registra a exclusão no log.
 *
 * @param {string} id_imobiliario_pessoa - O ID da associação a ser removida.
 * @returns {void}
 *
 * @example
 * const idAssociacao = "789";
 * removePessoaFromImobiliario(idAssociacao);
 */

export function removePessoaFromImobiliario(id_imobiliario_pessoa: string): void {
  del(id_imobiliario_pessoa, "imobiliario_pessoa");

  logDelete({
    id_origem: id_imobiliario_pessoa,
    nome_tabela: "imobiliario_pessoa"
  });
}

/**
 * Remove uma associação entre uma edificação e um registro de imóvel e registra a exclusão no log.
 *
 * @param {string} id_imobiliario_edificacao - O ID da associação a ser removida.
 * @returns {void}
 *
 * @example
 * const idAssociacao = "101";
 * removeEdificacaoFromImobiliario(idAssociacao);
 */

export function removeEdificacaoFromImobiliario(id_imobiliario_edificacao: string): void {
  del(id_imobiliario_edificacao, "imobiliario_edificacao");

  logDelete({
    id_origem: id_imobiliario_edificacao,
    nome_tabela: "imobiliario_edificacao"
  });
}

/**
 * Adiciona uma edificação a um registro de imóvel e executa operações relacionadas, como a criação de registros e logs.
 *
 * @param {string} id_edificacao - O ID da edificação a ser adicionada.
 * @param {string} id_imobiliario - O ID do registro de imóvel ao qual a edificação será associada.
 * @returns {ImobiliarioEdificacao} Um objeto representando a associação entre a edificação e o imóvel.
 *
 * @example
 * const idEdificacao = "222";
 * const idImobiliario = "333";
 * const associacao = addEdificacaoToImobiliario(idEdificacao, idImobiliario);
 * console.log("Edificação associada ao imóvel:", associacao);
 */

export function addEdificacaoToImobiliario(id_edificacao: string, id_imobiliario: string): GenericObject {
  let edificacaoImobiliarioStructure = getStructure("imobiliario_edificacao");

  edificacaoImobiliarioStructure.id = uuid();
  edificacaoImobiliarioStructure.id_imobiliario = Number(id_imobiliario);
  edificacaoImobiliarioStructure.id_edificacao = Number(id_edificacao);

  put(edificacaoImobiliarioStructure.id, "imobiliario_edificacao", edificacaoImobiliarioStructure);

  logInsert({
    fid: edificacaoImobiliarioStructure.id,
    nome_tabela: "imobiliario_edificacao"
  });

  logUpdate({
    id_origem: edificacaoImobiliarioStructure.id,
    nome_tabela: "imobiliario_edificacao",
    nome_campo: "id_edificacao",
    tipo_campo: "integer",
    valor_campo: id_edificacao
  });

  logUpdate({
    id_origem: edificacaoImobiliarioStructure.id,
    nome_tabela: "imobiliario_edificacao",
    nome_campo: "id_imobiliario",
    tipo_campo: "integer",
    valor_campo: id_imobiliario
  });

  return edificacaoImobiliarioStructure;
}

/**
 * Cria uma nova mídia (arquivo) e realiza operações relacionadas, como envio de arquivo, registro e log.
 *
 * @async
 * @param {string} id_origem - O ID de origem associado à mídia.
 * @param {"lote" | "imobiliario"} camada - A camada à qual a mídia está associada ("lote" ou "imobiliario").
 * @param {object} arquivo - Os dados do arquivo a ser criado, incluindo extensão, nome e conteúdo em base64.
 * @param {string} arquivo.extensao - A extensão do arquivo.
 * @param {string} arquivo.nome - O nome do arquivo.
 * @param {string} arquivo.base64 - O conteúdo do arquivo em base64.
 * @returns {Promise<Midia>} Uma promessa que será resolvida com um objeto representando a mídia criada.
 * @throws {Error} Lança um erro se ocorrer um problema durante o processo de criação.
 *
 * @example
 * const idOrigem = "123";
 * const camada = "lote";
 * const arquivo = {
 *   extensao: "jpg",
 *   nome: "imagem.jpg",
 *   base64: "base64_encoded_data_here"
 * };
 *
 * try {
 *   const novaMidia = await createMidia(idOrigem, camada, arquivo);
 *   console.log("Mídia criada com sucesso:", novaMidia);
 * } catch (error) {
 *   console.error("Erro ao criar mídia:", error);
 * }
 */

export async function createMidia(
  id_origem: string,
  camada: "lote" | "imobiliario",
  arquivo: {
    extensao: string;
    nome: string;
    base64: string;
  },
): Promise<Midia> {
  const post = await sendFile(arquivo.nome, arquivo.base64, arquivo.extensao);

  let midiaStructure = getStructure("midia");

  midiaStructure.id = uuid();
  midiaStructure.nome_camada = camada;
  midiaStructure.id_origem = Number(id_origem);
  midiaStructure.link = post.data.link.includes("http") ? post.data.link : "http://" + post.data.link;
  midiaStructure.nome = arquivo.nome;
  midiaStructure.extensao = arquivo.extensao;

  logInsert({
    fid: midiaStructure.id,
    nome_tabela: "midia"
  });

  logUpdate({
    id_origem: midiaStructure.id,
    nome_tabela: "midia",
    nome_campo: "nome_camada",
    tipo_campo: "text",
    valor_campo: camada
  });

  logUpdate({
    id_origem: midiaStructure.id,
    nome_tabela: "midia",
    nome_campo: "id_origem",
    tipo_campo: "integer",
    valor_campo: midiaStructure.id_origem
  });

  logUpdate({
    id_origem: midiaStructure.id,
    nome_tabela: "midia",
    nome_campo: "link",
    tipo_campo: "text",
    valor_campo: midiaStructure.link
  });

  logUpdate({
    id_origem: midiaStructure.id,
    nome_tabela: "midia",
    nome_campo: "nome",
    tipo_campo: "text",
    valor_campo: midiaStructure.nome
  });

  logUpdate({
    id_origem: midiaStructure.id,
    nome_tabela: "midia",
    nome_campo: "extensao",
    tipo_campo: "text",
    valor_campo: midiaStructure.extensao
  });

  if (["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg", "ico", "avif", "apng"].includes(arquivo.extensao)) {
    midiaStructure.tipo = 1;
  } else {
    midiaStructure.tipo = 2;
  }

  logUpdate({
    id_origem: midiaStructure.id,
    nome_tabela: "midia",
    nome_campo: "tipo",
    tipo_campo: "integer",
    valor_campo: midiaStructure.tipo
  });

  put(midiaStructure.id, "midia", midiaStructure);

  return midiaStructure as Midia;
}

/**
 * Remove uma mídia (arquivo) com o ID especificado e registra a exclusão no log.
 *
 * @async
 * @param {string} id - O ID da mídia a ser removida.
 * @throws {Error} Lança um erro se ocorrer um problema durante o processo de remoção.
 *
 * @example
 * const idMidia = "456";
 * try {
 *   await removeMidia(idMidia);
 *   console.log("Mídia removida com sucesso.");
 * } catch (error) {
 *   console.error("Erro ao remover mídia:", error);
 * }
 */

export async function removeMidia(id: string) {
  logDelete({
    id_origem: id,
    nome_tabela: "midia"
  });

  del(id, "midia");
}

/**
 * Cria uma nova mensagem associada a um imóvel e lote.
 * @param {string} id_imobiliario - O ID do imóvel associado à mensagem.
 * @param {string} id_lote - O ID do lote associado à mensagem.
 * @returns {Promise<Object>} Retorna um objeto representando a nova mensagem criada.
 */
export async function createMensagem(id_imobiliario: string, id_lote: string) {
  let mensagensStructure = getStructure("mensagens");

  mensagensStructure.id = uuid();
  mensagensStructure.id_imobiliario = id_imobiliario;
  mensagensStructure.id_lote = id_lote;

  put(mensagensStructure.id, "mensagens", mensagensStructure);

  logInsert({
    fid: mensagensStructure.id,
    nome_tabela: "mensagens"
  });

  logUpdate({
    id_origem: mensagensStructure.id,
    nome_tabela: "mensagens",
    nome_campo: "id_imobiliario",
    tipo_campo: "integer",
    valor_campo: mensagensStructure.id_imobiliario
  });

  logUpdate({
    id_origem: mensagensStructure.id,
    nome_tabela: "mensagens",
    nome_campo: "id_lote",
    tipo_campo: "integer",
    valor_campo: mensagensStructure.id_lote
  });

  return mensagensStructure;
}

/**
 * Cria uma nova visita associada a um imóvel.
 * @param {string} id_imobiliario - O ID do imóvel associado à visita.
 * @returns {Promise<Object>} Retorna um objeto representando a nova visita criada.
 */
export async function createVisita(id_imobiliario: string) {
  let visitasStructure = getStructure("visitas");

  visitasStructure.id = uuid();
  visitasStructure.id_imobiliario = id_imobiliario;

  put(visitasStructure.id, "visitas", visitasStructure);

  logInsert({
    fid: visitasStructure.id,
    nome_tabela: "visitas"
  });

  logUpdate({
    id_origem: visitasStructure.id,
    nome_tabela: "visitas",
    nome_campo: "id_imobiliario",
    tipo_campo: "integer",
    valor_campo: visitasStructure.id_imobiliario
  });

  return visitasStructure;
}

/**
 * Remove uma mensagem.
 * @param {string} id_mensagens - O ID da mensagem.
 */
export function removeMensagem(id_mensagens: string): void {
  del(id_mensagens, "mensagens");

  logDelete({
    id_origem: id_mensagens,
    nome_tabela: "mensagens"
  });
}

/**
 * Remove uma visita.
 * @param {string} id_mensagens - O ID da visita.
 */
export function removeVisita(id_visitas: string): void {
  del(id_visitas, "visitas");

  logDelete({
    id_origem: id_visitas,
    nome_tabela: "visitas"
  });
}

/**
 * Verifica se uma inscrição cartográfica ou um número cadastro específicos já existem na tabela especificada na base local da aplicação.
 *
 * @param {string} table - A tabela onde o campo será procurado.
 * @param {string} id - O valor a ser comparado na busca da inscrição cartográfica.
 * @param {CamposDeValorUnico} campo - O nome do campo pelo qual a busca será realizada.
 * @param {string} currentId - O ID atual da feição para ser excluído da busca.
 * @returns {Promise<boolean>} - Uma Promise que resolve para true se a inscrição cartográfica existir, ou false se não existir ou houver um erro.
 * @throws {Error} - Lança um erro se ocorrer um problema ao realizar a busca.
 */
export function verifyIfInscricaoOrNumeroCadastroAlreadyExistsOnLocalDatabase(
  table: Tabelas,
  value: string,
  campo: CamposDeValorUnico,
  currentId: string,
): boolean {
  const tableContent = getAll(table);

  if (!tableContent) {
    return false;
  }

  const itensThatExists = tableContent
    .filter((tableItem) => tableItem?.[campo] === value)
    .filter((tableItem) => tableItem.id != currentId);

  if (itensThatExists.length > 0) {
    return true;
  } else {
    return false;
  }
}
