import { OpenInFull } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Slide,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/pt-br";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { CamposSelect, ProjetosCamadasFormulariosCampo, Tabelas } from "../../../interfaces";
import { DashboardContext } from "../../../providers/Dashboard";
import { DebounceContext } from "../../../providers/Debounce";
import {
  getDescriptions,
  getDescriptionsById,
  getDescriptionsByName,
  verifyIfInscricaoOrNumeroCadastroAlreadyExists,
} from "../../../services/api";
import { getValue, patch } from "../../../services/db";
import { logUpdate } from "../../../services/log";
import { converteTipoCampo } from "../../../services/utils";
import { useTextArea } from "./hooks";
import "./styles.css";
import { isNumber } from "is-number-ts";

export default function Input(props: { tabela: Tabelas; campo: ProjetosCamadasFormulariosCampo }) {
  const { metadata, errors, setErrors, reloadFields } = useContext(DashboardContext);
  const { handleValidate } = useContext(DebounceContext);
  const { openDialog, setOpenDialog, styleTextArea, style } = useTextArea();

  const limitRadioGroup = 10;

  const theme = useTheme();

  const [value, setValue] = useState<any>("");
  const [isLoad, setLoad] = useState<boolean>(false);

  const [search, setSearch] = useState<string | undefined>(undefined);

  const inputId = `${metadata[`id_${props.tabela}`]!}@${props.tabela}@${props.campo.tblNomeColuna}`;

  // Campos que nao serão carregados no input
  const excludedFields = ["geom"];

  async function validate(value: any) {
    if (!isLoad) return;

    if (
      (props.campo.tblNomeColuna === "inscricao_cartografica" || props.campo.tblNomeColuna === "numero_cadastro") &&
      value !== ""
    ) {
      const valorNaoUnico = await verifyIfInscricaoOrNumeroCadastroAlreadyExists(
        props.tabela,
        value,
        props.campo.tblNomeColuna,
        metadata[`id_${props.tabela}`]!,
      );

      if (valorNaoUnico) {
        let storageErrors = JSON.parse(localStorage.errors);
        storageErrors = {
          ...storageErrors,
          [inputId]: {
            tabela: props.tabela,
            coluna: props.campo.tblNomeColuna,
            id: metadata[`id_${props.tabela}`]!,
            message: `O valor ${value} já está sendo utilizado`,
          },
        };

        setErrors(storageErrors);
        localStorage.errors = JSON.stringify(storageErrors);
      } else {
        let storageErrors = JSON.parse(localStorage.errors);
        delete storageErrors?.[inputId];
        localStorage.setItem("errors", JSON.stringify(storageErrors));
        setErrors(storageErrors);
      }
    } else if ((value === "" || !value || value === "-1") && props.campo.obrigatorio) {
      let storageErrors = JSON.parse(localStorage.errors);
      storageErrors = {
        ...storageErrors,
        [inputId]: {
          tabela: props.tabela,
          coluna: props.campo.tblNomeColuna,
          id: metadata[`id_${props.tabela}`]!,
          message: "Campo obrigatório",
        },
      };
      localStorage.errors = JSON.stringify(storageErrors);
      setErrors(storageErrors);
    } else {
      let storageErrors = JSON.parse(localStorage.errors);
      delete storageErrors?.[inputId];
      localStorage.setItem("errors", JSON.stringify(storageErrors));
      setErrors(storageErrors);
    }
  }

  function normalizeWord(text: string) {
    try {
      return text
        .toLowerCase()
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    } catch {
      return String(text);
    }
  }

  const inputProps = {
    fullWidth: true,
    label: props.campo.nome,
    disabled: props.campo.somenteVisualizacao || !metadata[`id_${props.tabela}`],
    value: value,
    required: props.campo.obrigatorio,
    error: !!errors?.[inputId],
    helperText: errors?.[inputId]?.message,
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!metadata[`id_${props.tabela}`]) return; // casos onde o id ainda não foi definido ou a filtragem de tabs nao retornou nada

      setValue(e.target.value);
      patch(metadata[`id_${props.tabela}`]!, props.tabela, props.campo.tblNomeColuna, e.target.value);
      logUpdate({
        id_origem: metadata[`id_${props.tabela}`]!,
        nome_tabela: props.tabela,
        nome_campo: props.campo.tblNomeColuna,
        valor_campo: e.target.value || null,
        tipo_campo: converteTipoCampo(props.campo.tipo),
      });

      if (props.campo.tblNomeColuna !== "inscricao_cartografica" && props.campo.tblNomeColuna !== "numero_cadastro") {
        validate(e.target.value);
      }
    },
    InputLabelProps: {
      sx: {
        "&.Mui-focused": {
          color: theme.palette.secondary.main,
        },
      },
    },
  };

  const inputDateProps = {
    value: moment(value),
    onChange: (e: any) => {
      if (!metadata[`id_${props.tabela}`]) return;
      setValue(e._d);
      patch(metadata[`id_${props.tabela}`]!, props.tabela, props.campo.tblNomeColuna, e._d);
      logUpdate({
        id_origem: metadata[`id_${props.tabela}`]!,
        nome_tabela: props.tabela,
        nome_campo: props.campo.tblNomeColuna,
        valor_campo: e._d,
        tipo_campo: converteTipoCampo(props.campo.tipo),
      });
      validate(e.target);
    },
  };

  useEffect(() => {
    if (!metadata[`id_${props.tabela}`]) return; // casos onde o id ainda não foi definido ou a filtragem de tabs nao retornou nada

    if (excludedFields.includes(props.campo.tblNomeColuna)) {
      return;
    }

    if (value === "" && !isLoad) {
      let retrievedValue = getValue(metadata[`id_${props.tabela}`]!, props.tabela, props.campo.tblNomeColuna);

      if (props.campo.tipo === 5 && retrievedValue === null) {
        retrievedValue = "-1";
        validate(retrievedValue);
      }

      retrievedValue && setValue(retrievedValue);
      validate(retrievedValue);
    } else {
      let retrievedValue = getValue(metadata[`id_${props.tabela}`]!, props.tabela, props.campo.tblNomeColuna);
      retrievedValue && setValue(retrievedValue);
      validate(retrievedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadFields]);

  useEffect(() => {
    validate(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function transformName(value: string) {
    if (value.includes("id_")) {
      return value.replace("id_", "").toLowerCase();
    } else {
      return value.toLowerCase();
    }
  }

  const [options, setOptions] = useState<CamposSelect[]>([]);

  function mapToOptions(data: any[], descricao: string): CamposSelect[] {
    return data.map((item) => ({
      "@id": item.id,
      id: item.id,
      nome: item[descricao],
      ativo: item.ativo ?? true,
      fator: item.fator ?? "",
      projetoCamadaFormularioCampoId: item.projetoCamadaFormularioCampoId ?? "",
      tblNomeColuna: item.tblNomeColuna ?? "",
      tblValorDb: item.id,
      projetoCamadaFormularioCampo: item.projetoCamadaFormularioCampo ?? "",
    }));
  }

  async function fetchDescriptions() {
    setLoad(true);

    try {
      const campoName = transformName(props.campo.tblNomeColuna);
      const data = await getDescriptions(campoName, props.campo.descricao);
      let allData = [...data];

      if (value) {
        const current = await getDescriptionsById(campoName, value);
        if (current.length > 0) {
          allData.push({
            nome: current[0].nome,
            id: current[0].id,
          });
        }
      }

      const dataOptions = mapToOptions(allData, props.campo.descricao);

      const allOptions = [
        ...options,
        ...dataOptions.filter((dataOption) => !options.some((option) => option.id === dataOption.id)),
      ];

      setOptions(allOptions);
      props.campo.tipo = 8;
    } catch (error) {
      console.error("Error fetching descriptions:", error);
    } finally {
      setLoad(false);
    }
  }

  async function fetchDescriptionsByName() {
    try {
      const data = await getDescriptionsByName(transformName(props.campo.nome), search, props.campo.descricao);

      const dataOptions = mapToOptions(data, props.campo.descricao);

      const allOptions = [
        ...options,
        ...dataOptions.filter((dataOption) => !options.some((option) => option.id === dataOption.id)),
      ];

      setOptions(allOptions);
    } catch (error) {
      console.error("Error fetching descriptions by name:", error);
    }
  }

  useEffect(() => {
    if (props.campo.descricao) {
      fetchDescriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.campo, value]);

  useEffect(() => {
    if (props.campo.descricao && search) {
      fetchDescriptionsByName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  if (isLoad && !excludedFields.includes(props.campo.tblNomeColuna))
    return (
      <Card>
        <CardContent sx={{ padding: 2 }}>
          <Skeleton height={80} />
        </CardContent>
      </Card>
    );

  return (
    <>
      {!excludedFields.includes(props.campo.tblNomeColuna) && (
        <Card>
          <CardContent sx={{ padding: 2 }}>
            {props.campo.tipo === 1 && (
              <>
                <TextField
                  variant="filled"
                  inputMode="text"
                  {...inputProps}
                  onChange={(e) => {
                    handleValidate(e.target.value, validate);
                    inputProps.onChange(e);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <OpenInFull
                          sx={{
                            opacity: 0.5,
                          }}
                          onClick={() => setOpenDialog(true)}
                          style={{ cursor: "pointer" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />

                <Dialog
                  open={openDialog}
                  onClose={() => setOpenDialog(false)}
                  TransitionComponent={Transition}
                  maxWidth="xl"
                >
                  <Box sx={style}>
                    <Typography variant="h5">{props.campo.nome}</Typography>
                    <TextareaAutosize
                      {...inputProps}
                      style={styleTextArea}
                      onChange={(e) => {
                        handleValidate(e.target.value, validate);
                        inputProps.onChange(e);
                      }}
                    />
                    <Button color="success" variant="contained" onClick={() => setOpenDialog(false)}>
                      Ok
                    </Button>
                  </Box>
                </Dialog>
              </>
            )}

            {props.campo.tipo === 2 && <TextField variant="filled" inputMode="numeric" {...inputProps} />}

            {props.campo.tipo === 3 && <TextField variant="filled" inputMode="decimal" {...inputProps} />}

            {props.campo.tipo === 5 && props.campo.camposSelect.length < limitRadioGroup && (
              <FormControl required={inputProps.required} error={inputProps.error}>
                <Tooltip title={props.campo.nome} placement="right-end">
                  <FormLabel
                    sx={{
                      "&.Mui-focused": {
                        color: theme.palette.secondary.main,
                      },
                    }}
                  >
                    {props.campo.nome}
                  </FormLabel>
                </Tooltip>
                <RadioGroup value={inputProps.value} onChange={inputProps.onChange}>
                  {props.campo.camposSelect
                    .sort((a, b) => a.nome?.localeCompare(b.nome))
                    .map((option) => {
                      return (
                        <Tooltip key={option.nome} title={option.nome} placement="right-end">
                          <FormControlLabel
                            value={option.tblValorDb}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: theme.palette.secondary.main,
                                  },
                                }}
                              />
                            }
                            label={option.nome}
                          />
                        </Tooltip>
                      );
                    })}
                  <Tooltip key={`${props.campo.nome}_null`} title={"Não atribuído"} placement="right-end">
                    <FormControlLabel
                      value={"-1"}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: theme.palette.secondary.main,
                            },
                          }}
                        />
                      }
                      label={"Não atribuído"}
                    />
                  </Tooltip>
                </RadioGroup>
                <FormHelperText>{errors?.[inputId]?.message}</FormHelperText>
              </FormControl>
            )}

            {props.campo.tipo === 6 && (
              <FormControl>
                <Tooltip title={props.campo.nome} placement="right-end">
                  <FormLabel
                    sx={{
                      "&.Mui-focused": {
                        color: theme.palette.secondary.main,
                      },
                    }}
                  >
                    {props.campo.nome}
                  </FormLabel>
                </Tooltip>
                <RadioGroup value={inputProps.value} onChange={inputProps.onChange}>
                  <Tooltip key={props.campo.nome + `-sim`} title={`Sim`} placement="right-end">
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: theme.palette.secondary.main,
                            },
                          }}
                        />
                      }
                      label={`Sim`}
                    />
                  </Tooltip>
                  <Tooltip key={props.campo.nome + `-nao`} title={`Não`} placement="right-end">
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: theme.palette.secondary.main,
                            },
                          }}
                        />
                      }
                      label={`Não`}
                    />
                  </Tooltip>
                </RadioGroup>
              </FormControl>
            )}

            {props.campo.tipo === 7 && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker {...inputProps} {...inputDateProps} />
              </LocalizationProvider>
            )}

            {props.campo.tipo === 8 && (
              <FormControl variant="filled" sx={{ width: "100%" }}>
                {options.length > 0 && (
                  <Tooltip
                    title={options.find((item) => item.tblValorDb === value)?.nome || props.campo.nome}
                    placement="top"
                  >
                    <div>
                      <InputLabel
                        sx={{
                          "&.Mui-focused": {
                            color: theme.palette.secondary.main,
                          },
                        }}
                      >
                        {inputProps.label}
                      </InputLabel>
                      <Select
                        {...inputProps}
                        variant="filled"
                        onChange={(e) => inputProps.onChange(e as any)}
                        onClose={() => fetchDescriptions()}
                        MenuProps={{ autoFocus: false }}
                      >
                        <ListSubheader>
                          <TextField
                            variant="standard"
                            autoFocus
                            inputMode="search"
                            fullWidth
                            onChange={(e) => setSearch(e.target.value || "")}
                            placeholder="Pesquisar..."
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {options.length !== 0 ? (
                          options
                            .filter((item) => item.nome)
                            .sort((a, b) => {
                              try {
                                if (isNumber(a.nome) && isNumber(b.nome)) {
                                  return Number(a.nome) - Number(b.nome);
                                }

                                return a.nome?.localeCompare(b.nome);
                              } catch (error) {
                                console.error(`Erro ao comparar os nomes ${a.nome} e ${b.nome}`, error);
                                return 0;
                              }
                            })
                            .filter((item) =>
                              normalizeWord(item.nome || String(item.id)).includes(normalizeWord(search || "")),
                            )
                            .map((item, index) => {
                              return (
                                <MenuItem key={`${item.nome}_${index}`} value={item.id}>
                                  {`${item.nome || item.id} (${item.id})`}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem key={`undefined`} value={undefined}>
                            Nenhum dado encontrado
                          </MenuItem>
                        )}
                      </Select>
                    </div>
                  </Tooltip>
                )}
              </FormControl>
            )}

            {props.campo.camposSelect.length >= limitRadioGroup && !props.campo.descricao && (
              <FormControl variant="filled" sx={{ width: "100%" }}>
                <Tooltip
                  title={props.campo.camposSelect.find((item) => item.tblValorDb === value)?.nome || "Não atribuído"}
                  placement="top"
                >
                  <div>
                    <InputLabel
                      sx={{
                        "&.Mui-focused": {
                          color: theme.palette.secondary.main,
                        },
                      }}
                    >
                      {inputProps.label}
                    </InputLabel>
                    <Select
                      {...inputProps}
                      variant="filled"
                      onChange={(e) => inputProps.onChange(e as any)}
                      onClose={() => setSearch(undefined)}
                      MenuProps={{ autoFocus: false }}
                      placeholder="Pesquisar..."
                    >
                      <ListSubheader>
                        <TextField
                          variant="standard"
                          autoFocus
                          inputMode="search"
                          fullWidth
                          onChange={(e) => setSearch(e.target.value || "")}
                          placeholder="Pesquisar..."
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      {props.campo.camposSelect.length !== 0 ? (
                        props.campo.camposSelect
                          .filter((item) => normalizeWord(item.nome).includes(normalizeWord(search || "")))
                          .map((item, index) => {
                            return (
                              <MenuItem key={`${item.nome}_${index}`} value={item.tblValorDb}>
                                {`${item.nome || item.tblValorDb} (${item.tblValorDb})`}
                              </MenuItem>
                            );
                          })
                      ) : (
                        <MenuItem key={`undefined`} value={undefined}>
                          Nenhum dado encontrado
                        </MenuItem>
                      )}
                      <MenuItem key={`${props.campo.nome}_null`} value={"-1"}>
                        {`Não atribuído`}
                      </MenuItem>
                    </Select>
                  </div>
                </Tooltip>
              </FormControl>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
